:root {
  --text-color: #232323;
  --cardBG-color:#092043;
  --green: #84bc34;
  --lightGreen: #c3db9a;
}

body {
  margin: 0;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
/*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8rem;
  line-height: 1.3em;
  min-height: 100vh;
  background-image: url('../images/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input{
  appearance: none;
  background: transparent;
  border: 2px solid var(--green);
  border-radius: 0.6rem;
  color: #1a2935;
  font-size: 1.1rem;
  height: 3.6rem;
  outline: none;
  padding: 0 1.6rem;
  position: relative;
  transition: all .1s;
  width: 90%;
  z-index: 1;   
}

.inputLabel{
  align-items: center;
  background-color: #d8eabd;
  border-radius: 4px;
  color: #1a2935;
  display: flex;
  font-size: 1.4rem;
  font-weight: 500;
  height: 2rem;
  left: 1.0rem;
  margin: 0;
  padding: 0 0.8rem;
  position: absolute;
  top: -1px;
  transition: all .2s;
  z-index: 2;
  font-size: 0.8rem;
}



  
.AppHeader {
    min-height: 5rem;
    width: 100%;
    display: flex;
    transition: all .2s;
    z-index: 9;
  }
  
  .AppHeader .AppLogoWrap{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
  }
  
  .AppLogoWrap .AppLogo{
    height: 2.9rem;
    pointer-events: none;
  }
  
  .AppLogoWrap .AppLogoText{
    font-size: 1.5rem;
    margin-left: 1rem;
  }
  
  .AppHeader .AppUserInfo{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1rem;
  }
  
  .AppUserInfo .AppUsername{
    font-size: 1.5rem;
    margin-right: 1rem;
  }
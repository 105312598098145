.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    text-align: center;
    /*background-color: white;*/
    color: #282c34;
  }

.landingPageWrap{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: calc(100vh - 15rem);
}

.landingPageContentWrap{
    max-width: 100%;
    width: 58.2rem;
}

.landingPageInput{
  padding: 1rem 0 0;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.landingPageBtn{
  background: #3993ff;
  width: 90%;
  color: #fff;
  border-radius: 0.8rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  height: 5rem;
  letter-spacing: .02em;
  outline: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  transition: all .09s linear;
  margin-top: 20px;
  text-align: center;
  line-height: 5rem;
}

.landingPageBtn:hover{
  background: #579ff8;
}

.smallBtn{
  width: 10%;
  height: 5rem;
  margin-left: 20px;
}

.inputLabelStart{
  left: 4rem;
  top: 33px;
}

.inputStart{
  width: 71%;
}

.landingPageInputText{

}
.GameAreaWrap{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 5rem);
    min-width: 100%;
    overflow-y: auto;
}

.debugButton{
    position: absolute;
}

/* **********************************************************************
*
*  GAME TABLE CONTENT
*
* **********************************************************************/

.GameTableWrap{
    align-items: center;
    display: flex;
    justify-content: center;
    margin: auto;
    padding-bottom: 0;
    width: 100%;
    height: 100%;
    height: -webkit-fill-available;
}

.GameTable{
    grid-template-rows: 8rem 1fr 13.4rem;
    grid-gap: 0.8rem;
    display: inline-grid;
    grid-template-areas:
        "left top right"
        "left table right"
        "left bottom right";
    grid-template-columns: 10.4rem 1fr 10.4rem;
    grid-template-rows: auto 1fr auto;
    margin: 0 auto;
    min-height: 200px;
    width: auto;
}

.Table-Module--top{
    align-items: stretch;
    grid-area: top;
    align-items: flex-end;
    padding-bottom: 0.8rem;
    align-items: center;
    display: flex;
    justify-content: center;
}

.Table-Module--left{
    grid-area: left;
    flex-direction: column-reverse;
    padding: 8rem 0;
    align-items: center;
    display: flex;
    justify-content: center;
}

.Table-Module--table{
    align-items: center;
    display: flex;
    justify-content: center;
    background: #d7e9ff;
    border-radius: 1.8rem;
    box-shadow: inset 0 0 10px #1a2935;
    grid-area: table;
    height: auto;
    margin: 0 auto;
    min-height: 8rem;
    min-width: 33.8rem;
    /*padding: 0 1.6rem;*/
    position: relative;
    width: 100%;
}

.TableInfos{
    
}

.TableInfos button{
    background: #74b3ff;
    align-items: center;
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    display: inline-flex;
    font-size: 1.2rem;
    font-weight: bold;
    height: 5.6rem;
    letter-spacing: .02em;
    outline: 0;
    padding: 15px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .09s linear;
}

.TableInfos button:hover{
    background: #8dbdf8;
}

.Table-Module--right{
    grid-area: right;
    flex-direction: column-reverse;
    padding: 8rem 0;
    align-items: center;
    display: flex;
    justify-content: center;
}

.Table-Module--bottom{
    align-items: stretch;
    grid-area: bottom;
    align-items: flex-start;
    padding: 1.2rem 0 0.4rem;
    align-items: center;
    display: flex;
    justify-content: center;
}

.invitePlayerWrap{
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 15px;

}

.inviteBtn{
	background-color:transparent;
	display:inline-block;
	cursor:pointer;
	color:var(--green);
	font-size:1.2rem;
	padding:9px 23px;
	text-decoration:none; 
    border-radius: 4px; 
    border: var(--green) 2px dotted;
    transition: 0.3s;
}

.inviteBtn:hover{
    border: var(--green) 2px solid;
}

.closeBtn{
    background-color: transparent;
    display: inline-block;
    cursor: pointer;
    color: var(--green);
    font-size: 1.2rem;
    padding: 5px 15px;
    text-decoration: none;
    border-radius: 4px;
    border: var(--green) 2px dotted;
    transition: 0.3s;
    margin-right: 15px;
    margin-top: 10px;
}

.closeBtn:hover{
    border: var(--green) 2px solid;
}

.inviteBtnSpecial{
	background-color:transparent;
	display:inline-block;
	cursor:pointer;
	color:var(--green);
	font-size:1.1rem;
    font-weight: bold;
	padding:9px 23px;
	text-decoration:none; 
    border: transparent;  
    text-transform: uppercase;
}

.inviteBtnSpecial:hover{
    color: var(--lightGreen);    
}

/* **********************************************************************
*
*  MODAL CONTENT
*
* **********************************************************************/

.modalWrapper{
    background: rgba(26,41,53,.8);
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 101;
    font-size: 1.1rem;
}

.modalModule{
    width: 43rem;
    background: #fff;
    border-radius: 1.6rem;
    display: flex;
    left: 50%;
    max-height: calc(100% - 2.4rem);
    max-width: calc(100% - 2.4rem);
    outline: 0;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50.1%);
    z-index: 102; 
    overflow: hidden;
}

.modalModuleHeader{
    background-color: #fff;
    left: 0;
    position: absolute;
    right: 2px;
    top: 0;
    transition: all .2s;
    z-index: 3;
    display: flex;
    justify-content: flex-end;
}

.modalModuleContent{
    padding: 0 4.8rem 4.8rem;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    width: 100%;
    color: var(--text-color);
    margin-top: 50px;
}

.modalContentSpacer{
    height: 1.0rem;
}

.modalContentHeader{
    font-size: 2.4rem;
    font-weight: 700;
    color: #1a2935;
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2em;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-bottom: 30px;
}

.modalContentInput{
    padding: 1rem 0 0;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
}

.modalContentBtn{
    background: #3993ff;
    width: 100%;
    color: #fff;
    height: 4.8rem;
    border-radius: 0.8rem;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: bold;
    height: 5.6rem;
    letter-spacing: .02em;
    outline: 0;
    padding: 0;
    position: relative;
    text-decoration: none;
    transition: all .09s linear;
    margin-top: 20px;
    text-align: center;
    line-height: 5.6rem;
}
.modalContentInputText{
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 2px solid #e8e9ea;
    border-radius: 0.6rem;
    color: #1a2935;
    font-size: 1.6rem;
    height: 5.6rem;
    outline: none;
    padding: 0 1.6rem;
    position: relative;
    transition: all .1s;
    width: 100%;
    z-index: 1;
    height: 4.8rem;
}


/* **********************************************************************
*
*  CARDS CONTENT
*
* **********************************************************************/

.PlayerWrap{
    max-width: 10.4rem;
    width: 10.4rem;
    height: 7rem;
}

.GameCardWrapper{
    max-width: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-width: 100%;
    position: relative;
    margin-top: 0.3rem;
}

.GameCard{
    border-color: var(--cardBG-color);
    border-style: solid;
    border-radius: 0.6rem;
    border-width: 2px;
    flex-shrink: 0;
    height: 5.7rem;
    width: 3.5rem;
    display: block;
}

.GameCard-Backside{
    height: calc(100% - 24px);
    width: 100%;
    margin-top: 24px;
}

.GameCardSelected{
    background: var(--cardBG-color);
    border-radius: 0.6rem;
    flex-shrink: 0;
    height: 6rem;
    width: 3.5rem;
    display: block;
}

.GameCardSelected-Backside{
    height: calc(100% - 24px);
    width: 100%;
    margin-top: 24px;
}

.GameCardSelected-Number{
    height: calc(100% - 24px);
    width: 100%;
    margin-top: 24px;
    color: var(--green);
    font-weight: bold;
}

.PlayerName{
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    font-size: 1.0rem;
    line-height: 1.2rem;
    color: var(--text-color);
    font-weight: bold;
}

.cardValue{
    height: calc(100% - 24px);
    width: 100%;
    margin-top: 24px;
}

/* **********************************************************************
*
*  FOOTER CONTENT
*
* **********************************************************************/

.GameTableFooter{
    flex-shrink: 0;
    height: 9rem;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.GameTableFooterHeader{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 2rem;
    width: 100%;
    color: var(--text-color);
    font-size: 1.0rem;
}

.GameTableCards{
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 7rem;
    width: 100%;
}

.notselectedCard{
    border-color: var(--cardBG-color);
    border-style: solid;
    border-radius: 0.6rem;
    border-width: 3px;
    flex-shrink: 0;
    height: 5rem;
    width: 3rem;
    display: block;
    margin: 10px;
    align-self: flex-end;
    cursor: pointer;
    transition: all .1s linear;
    display: inline-block;
}

.notselectedCard:hover{
    -webkit-box-shadow:0px 0px 47px 2px rgba(131,188,52,1);
    -moz-box-shadow: 0px 0px 47px 2px rgba(131,188,52,1);
    box-shadow: 0px 0px 47px 2px rgba(131,188,52,1);
    border-color: var(--green);
    color: var(--green);
}

.selectedCard{
    border-color: var(--green);
    color: var(--green);    
    border-style: solid;
    border-radius: 0.6rem;
    border-width: 3px;
    flex-shrink: 0;
    height: 5rem;
    width: 3rem;
    display: block;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    align-self: flex-end;
    cursor: pointer;
    transition: all .1s linear;
    display: inline-block;
}